import DatetimeRangePicker from './initializers/datetime-range-picker'
import DateRangePicker from './initializers/date-range-picker'
import Select2 from './initializers/select2'
import AjaxEvents from './ajax_events'

$(document).on('turbolinks:load', function() {
  DatetimeRangePicker()
  DateRangePicker()
  Select2()
  AjaxEvents()
})

$(document).on('climber:modalopen', '[data-modal="modal"]', function(e) {
  DatetimeRangePicker()
  DateRangePicker()
  Select2()
})

$(document).on("turbolinks:before-cache", function() {
	$('.select2-selector').select2('destroy')
})
