import moment from 'moment'

export default function () {
  $('.datetime-range').daterangepicker({
    timePicker: true,
    locale: {
      format: 'DD/M/Y hh:mm A'
    }
  });
}
