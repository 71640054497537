/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import Turbolinks from "turbolinks";
import ReactRailsUJS from "react_ujs";
var componentRequireContext = require.context("components", true);
Turbolinks.start();
window.Turbolinks = Turbolinks;
// Remove previous (native) events, and add Turbolinks'
ReactRailsUJS.detectEvents();
// (Optional) Clean up global namespace
delete window.Turbolinks;
ReactRailsUJS.useContext(componentRequireContext);

// jquery config
import jQuery from 'jquery'
window.$ = jQuery
window.jQuery = jQuery

// stimulus
import 'controllers'

// other Rails imports
import 'jquery-ujs'
require('@rails/activestorage').start()

import 'jgrowl'
import 'select2'
import 'bootstrap/dist/js/bootstrap'
import 'bootstrap-datepicker'

// Custom initializers
import 'src/javascripts/init'

console.log('Hello World from Webpacker')
