export default function () {
  $('.mentees-ajax-select2').select2 ({
    placeholder: $(this).data('placeholder'),
    theme: "bootstrap",
    minimumInputLength: 3,
    ajax: {
      url: '/admin/mentees/search',
      dataType: 'json',
      delay: 250,
      data: (params) =>
        ({
          q: params.term,
          page: params.page || 1
        })
    }
  })
  $('.cities-ajax-select2').select2 ({
    placeholder: $(this).data('placeholder'),
    theme: "bootstrap",
    minimumInputLength: 2,
    ajax: {
      url: '/admin/cities/search',
      dataType: 'json',
      delay: 250,
      data: (params) =>
        ({
          q: params.term,
          page: params.page || 1
        })
    }
  })
  $('.select2-selector').select2 ({
    placeholder: $(this).data('placeholder'),
    theme: "bootstrap",
    width: "element"
  })
  $('[data-behavior~=campus-search-select2]').select2 ({
    placeholder: $(this).data('placeholder'),
    theme: "bootstrap",
    minimumInputLength: 3,
    ajax: {
      url: '/admin/campuses',
      dataType: 'json',
      delay: 250,
      data: (params) => 
        ({
          q: params.term,
          page: params.page || 1
        }),
      processResults: function (data) {
        return {
          results: data.table.data.map(item => ({ id:item.id, text: item.name })),
          pagination: {
            more: data.table.facets.table.next_page
          }
        };
      },
    }
  })

  $('[data-behavior=country-search-select2]').select2 ({
    placeholder: $(this).data('placeholder'),
    theme: "bootstrap",
    minimumInputLength: 3,
    ajax: {
      url: '/admin/countries',
      dataType: 'json',
      delay: 250,
      data: (params) => 
        ({
          search_term: params.term,
        }),
      processResults: function (data) {
        return {
          results: data.map(item => ({ id:item, text: item })),
        };
      },
    }
  })
}
