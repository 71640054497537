import { Controller } from 'stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = ["rootSelector", "filterContainer", "firstFilter", "startMonth", "endMonth"]
  filterPaths = {
    "Specific mentees": [['mentees', '/admin/mentees/search']],
    "Workshops": [['workshops', "/admin/workshops/search"], ['batches', '/admin/batches/search']],
    "Packs": [['packs', "/admin/packs/search"], ['workshops', "/admin/workshops/search"], ['batches', '/admin/batches/search']],
    "All users": []
  }
  connect() {
    let stimulusController = this
    console.log("hello from StimulusJS")
    $('.select2-notifications').select2 ({
      placeholder: $(this).data('placeholder'),
      theme: "bootstrap",
      width: "element",
      ajax: {
        url: function () {
          return $(this).attr('data-ajax-url')
        },
        dataType: 'json',
        delay: 250,
        data: (params) => ({
            q: params.term,
            page: params.page || 1,
            ...stimulusController.selectedFilters()
        })
      }
    })
    $(".select2-notifications").on('select2:select', function () {
      console.log("list item selected");
      let event = new Event('change', { bubbles: true }) // fire a native event
      this.dispatchEvent(event);
    });
    $(".select2-selector").on('select2:select', function () {
      console.log("Root item selected");
      let event = new Event('change', { bubbles: true }) // fire a native event
      this.dispatchEvent(event);
    });
    // initialize the datepickerm
    $('.input-daterange').datepicker({
      startView: 'months',
      minViewMode: 'months',
      format: 'mm/yyyy',
      clearBtn: true,
      autoclose: true
    });
  }

  selectedFilters() {
    let activeTargets = this.filterContainerTargets.filter(
      el => el.classList.contains('active')
    ).reduce((acc, el) => {
      let selector = el.firstElementChild
      let selectorData = $(selector).select2('data').map(s => s.id)
      acc[selector.dataset.currentFilter] = selectorData
      return acc
    }, {})
    if(this.startMonthTarget.value) activeTargets["startMonth"] = this.startMonthTarget.value
    if(this.endMonthTarget.value) activeTargets["endMonth"] = this.endMonthTarget.value
    return activeTargets
  }

  filterChanged(e) {
    console.log(this.rootSelectorTarget.value)
    let new_filter_index = parseInt(e.target.dataset.position)
    let new_filter_settings = this.filterPaths[this.rootSelectorTarget.value][new_filter_index]
    this.filterContainerTargets.forEach((el) => {
      let selector = el.firstElementChild
      let container_index = parseInt(el.dataset.position) - 1
      if(container_index == new_filter_index && !(new_filter_settings === undefined)) {
        el.classList.add("active")
        selector.dataset.currentFilter = new_filter_settings[0]
        selector.setAttribute('name', new_filter_settings[0] + "[]")
        selector.dataset.ajaxUrl = new_filter_settings[1]
      }
      if(container_index > new_filter_index) {
        el.classList.remove("active")
        $(selector).empty()
      }
    })


  }
  rootChanged(e) {
    this.filterContainerTargets.forEach((el) => {
      let selector = el.firstElementChild
      el.classList.remove("active")
      $(selector).empty()
    })
    if(e.target.value != "All users") {
      this.firstFilterTarget.classList.add("active")
      this.firstFilterTarget.firstElementChild.setAttribute('name', this.filterPaths[e.target.value][0][0] + "[]")
      this.firstFilterTarget.firstElementChild.dataset.currentFilter = this.filterPaths[e.target.value][0][0]
      this.firstFilterTarget.firstElementChild.dataset.ajaxUrl = this.filterPaths[e.target.value][0][1]
    }
  }
}
