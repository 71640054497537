export default function() {
  // The trigger build button in website_sections
  $('main').on('ajax:success', '.jgrowl-action', (event, {success, message}) => {
    if(success) {
      $.jGrowl(message, { theme: "success", life: 6000 })
    }
    else {
      $.jGrowl(message, { theme: "failure", life: 6000 })
    }
  })
}
